import { useState } from "react";

import { logErrorToBackend } from "../utilities/helpers";

export function useLocalStorage<T>(key: string, initialValue: T | (() => T)) {
  const [value, setValue] = useState<T>(() => {
    const jsonValue = localStorage.getItem(key);

    try {
      if (jsonValue != null) return JSON.parse(jsonValue);
    } catch (error) {
      console.log(error);
    }

    if (typeof initialValue === "function") {
      return (initialValue as () => T)();
    } else {
      return initialValue;
    }
  });

  const isLocalStorageAvailable = () => {
    try {
      const x = "__storage_test__";
      localStorage.setItem(x, x);
      localStorage.removeItem(x);

      return true;
    } catch (e) {
      return false;
    }
  };

  if (isLocalStorageAvailable()) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);

      logErrorToBackend({
        name: "LocalStorage",
        message: error,
        stack:
          "Unable to write to localStorage in useLocalStorage.ts" +
          " Value:" +
          value +
          " Key:" +
          key,
        componentStack: "useLocalStorage",
        href: window.location.href,
      });
    }
  } else {
    logErrorToBackend({
      name: "LocalStorage",
      message: "LocalStorage error",
      stack:
        "Unable to write to localStorage in useLocalStorage.ts" +
        " Value:" +
        value +
        " Key:" +
        key,
      componentStack: "useLocalStorage",
      href: window.location.href,
    });
  }

  return [value, setValue] as [typeof value, typeof setValue];
}
